/*############## LAYOUTS ##############*/
html, body, #root {
	height: 100%;  
}

body {
	font-family: arial;
	color: black;
}

a {
	color: #000000;
}

a:hover {
	text-decoration: none;
	color: #000000;
}

#container {
	display: -webkit-flex;
	display: flex;
	flex-direction: column;
	-webkit-flex-direction: column;
	height:100%;
}

/*########### SPLIT #########*/
.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
    cursor: col-resize;
}

/*###### HEADER ######*/
header {
	min-height: 58px;
	background-color: lightgrey;
}

header.hide {
	overflow: hidden;
	height:58px;
}

#logo {
	float:left;
	margin: 10px;
	font-weight: 800;
}

#add-menu, #login, #button-show {
	float:left;
	margin: 10px 10px 0 0;
}

#button-show a {
	text-decoration: none;
}

#add-input {
	width:120px;
}

.gridoid {
	text-align: center;
    font-size: 10px;
}

/*### MENU ###*/
.menu-item {
	margin-left: 5px;
	font-size: larger;
	float:left;
}

.menu-add {
    margin: 0 12px 0 5px;
    cursor: pointer;
}

.menu-delete {
    margin: 0 12px 0 5px;
    cursor: pointer;
}

/*####### LOGIN ######*/
#login-top {
	margin: 0 0 10px;
}

/*###### CONTENT ######*/
#comments, #list, #more {
	height:100%;
	overflow-y: scroll;
	margin: 0 3px 0 0;}

#list {
	width: 21%;
}

#comments {
	width: 57%;
}

#more {
	width: 21%;
}

.full-screen .gutter {
	display:none;
}

.full-screen > div {
	width: 0%;
	flex-basis: inherit !important;
}

.full-screen .full-target {
	width: 100%;
}

/*#comments, #more {
	flex: 1 1 0;
}

#list {
	flex: 2 2 0;
}*/

#content {
	overflow-y: hidden;
	height: 100%;
	flex: 1 1 0;
	word-wrap: break-word;
}

/*### SUBREDDIT ####*/
#subreddit {
	display:flex;
	flex-direction: row;
	height: 100%;
	overflow: hidden;
	padding-top:5px;
}

#more {
	padding-bottom: 100px;
}

.hide-subreddit {
	width:100%;
	text-align: center;
	background-color: #eee;
	height:15px;
	font-size:10px;
	vertical-align: center;
	cursor: pointer;
}

#list-bottom {
	width:100%;
	text-align: center;
	font-size:10px;
	vertical-align: center;
	cursor: pointer;
	border-top: 5px solid white;
	background-color: #eeeeee;
}

/*#### LIST #####*/
.hide-images.list-item .thumb {
	display:none !important;
}

.hide-images.list-item .meta, .hide-images.list-item .title {
	margin-left: 10px;
}

.hide-images .hidemore-title svg {
	display:initial !important;
}

.list-item img{
	vertical-align: top;
}

.list-item {
	padding: 0 0 2em;
	flex-wrap: wrap;
	flex-direction:row;
	display:block;
	cursor: pointer;
}

.list-item .focus-arrow {
	font-size: 50px;
}

.list-item .hide-thumb-focus-arrow {
	font-size: 20px;
}

.list-item .thumb {
	float:left;
	margin: 5px 0 0 0 ;
	position: relative;
}

.list-item .thumb img {
	width:100px;
}

.list-item .title {
	margin: 0 0 0 105px;
}


.list-item .meta {
	margin: 0 0 0 105px;
	font-size:small;
}

.mobile .list-item .title {
	margin: 5px;
}

.mobile .list-item .meta {
	margin: 5px;
}

.mobile .list-item .thumb {
	float:none;
}

.mobile .list-item .thumb img {
	width: 100%;
	padding: 10px;
}

.clear {
	clear:both;
}

/*#### COMMENT ######*/
#comments-top {
	text-align: right;
	margin: 0 10px 0 0;
	cursor: pointer;
}

#comments-top:hover {
	background-color: #eee;
}

.full-screen .full-target #comments-top{
	height: 100%;
    position: absolute;
    background-color: #eee;
    width: 20px;
    margin: 0;
}

.full-screen .full-target #link-data, .full-screen .full-target .content {
	margin-left: 23px;
} 

.full-screen .content > .thread > .roll-up {
	display:none;
}

.full-screen .full-target .full-screen-text {
	display: none;
}

.links {
	position: relative;
}

#comments .content .even:first-child {
	border-top:none;
}

#link-data {
	margin: 0 0 0 4px;
}

#link-title {
	font-weight: bold;
}

.link-title .meta, .meta-item {
	margin: 0 5px 0 0;
}

.link-title a {
	font-weight:normal;
}

#link-data .meta{
	font-size: small;
}



.twitter, .facebook {
	display: none;
}

.twitter img, .facebook img {
	height:18px;
}

.list-item:hover .twitter, .list-item:hover .facebook, .thread-text:hover .twitter, .thread-text:hover .facebook {
	display: initial;
}

.thread {
	position: relative;
	border-left: .9vw solid white;
	clear:both;
}

.more {
	position: relative;
	border-left: .9vw solid white;
	clear:both;
    margin: 0 0 7px;
}

.more:hover, .thread:hover {
    padding-left: .3vw;
    border-left: 0.3vw dotted lightgrey;
    margin-left: .3vw;
}

.roll-up {
	height: 100%;
    position: absolute;
    width: 1vw;
    margin-left: -1vw;
    cursor: pointer;
}

.roll-up:hover {
    background-color: lightgrey;
    border: 5px;
    border-color: white !important;
    border-style: solid;
    border-width: 6px;
    border-radius: 20px;
}

.content > .thread, .content > .more {
	padding-left: 0vw;
	border-width: 0vw;
	margin-left: 0vw;
}

.content > .thread {
	margin-bottom:2em;
}

.content > .thread.hidden {
	margin-bottom: 0;
}

.more-button, .continue-reddit {
	font-size: small;
	margin: 0 5px 0 0;
	padding: 3px;
	cursor: pointer;
	display: inline;
}

.comment-meta, .comment-meta a {
	font-size: small;
	color: #949494;
	text-decoration: none;
}

.comment-meta a:hover {
    color: #505050;
}

.reddit-link {
	font-weight: bold;
}

.expand {
	display: block;
	position: absolute;
	width: 10px;
	text-align: center;
	padding: 0px 0;
	top: 5px;
	left: 0;
	color: black;
	transition: background-color 200ms;
}

.more-expand {
	margin-left:3px;
	display: block;
	height: 100%;
	width: 10px;
	text-align: center;
	background-color: #dfdfdf;
	padding: 0px 0;
	top: 0;
	left: 0;
	color: black;
	transition: background-color 200ms;
}

.hidden > div {
	display:none;
}

.hidden > .thread-text {
	height: 18px;
    overflow: hidden;
    display: block;
    border: none;
    background: none !important;
    color: #c4c4c4;
    float: left;
    cursor: pointer;
    width: 6vw;
    font-size: 12px;
    margin-bottom: -1px;
    margin-right: 20px;
}

.hidden > .thread-text .comment-meta {
	display:none;
}

.hidden .thread, .hidden.thread {
	clear: none;
}

.hidden > .expand {
	display:initial
}

.hidden > .expand:after {
	content: "-";
}

/*####### MORE ########*/
.embedly-card-hug {
	width: 100%;
	max-width:100% !important;
}

.embedly-card-hug iframe {
	width: 100%;
}

#saved-subreddits-menu, #recent-subreddits-menu, #popular-subreddits-menu {
	margin-top:10px;
}

/*####### FOOTER ########*/
#footer {
    position: fixed;
    bottom: 10px;
    right: 15px;
	max-width: 454px;
}

/*############## DESIGN ##############*/
/* ####### LAYOUT ######*/
.comment-link {
	color: #212529;
	cursor: pointer;
	font-weight: bold;
}

.gutter {
	background-color: #ebebeb;
	background-repeat: no-repeat;
	background-position: 50%;
	margin: 0 4px;
}

.gutter:hover {
	cursor: col-resize;
	background-color: darkgrey;
}

/* ######## HEADER ####*/
#login-link a, #login-link a:hover {
	color: #000000;
}

.menu-delete {
    border-color: #000000;
	color: grey;
}

.menu-add {
	color: grey;
}

a.menu-link:hover {
	text-decoration: underline;
}

.ReactModal__Overlay--after-open {
	overflow: scroll;
}

/* ######### LIST #####*/
.meta-item a, .meta {
	color: #949494;
}

/* ######## COMMENTS ####*/

/*#comments .odd {
    border-color: #ffffff;
}*/

.btn.more-button {
	background:none;
	color: #000000;
	cursor: pointer;
	border: none;
	font-size: x-small;
}


#main-menu {
	padding: 0 20px 20px 20px;
}

/*.odd {
	background-color: #ededed;
}*/

.even {
	background-color: #ffffff;
}

.more-button {
	background-color: #ededed;	
}

.thread-text {
	margin: 0 0 5px;
    border-right: none;
    border-left: none;
	padding: 0 3px 5px;
	border-bottom: 1px solid lightgrey;
	cursor: pointer;
}

/*########## SCROLLBAR #######*/


/*########## PAGE #######*/
.page {
	max-width:1200px;
	margin: auto;
}

/*########## ABOUT ########*/
#about-content {
	margin:50px;
}

#terms-content {
	margin:50px;
}

/*########## MOBILE ########*/
.mobile .gutter.gutter-horizontal {
	flex-basis: 20px !important;
}


@media only screen and (max-width: 700px) {
	.list-item .thumb img {
		width: 100%
	}
	
	.list-item .title, .list-item .meta {
		margin: 0;
	}

	.list-item .title {
		clear: both;
	}

	.list-item .focus-arrow, .list-item .hide-thumb-focus-arrow {
		display:none;
	}

	#search-reddit-row {
		max-height: calc(80vh - 100px) !important;
	}

	#footer {
		max-width: 100% !important;
		font-size: .75em;
    	width: 100%;
		right: 0;
	}

	#chrome-bonus {
		display:none;
	}

	#button-show {
		width: 100%;
		display:flex;
		flex-wrap:wrap;
		margin-right: 0px;
	}

	#footer-break {
		display: initial !important;
	}

	#footer .show-arrow {
		flex: 0 1 50%; 
		order: 1;
		padding: 0px;
	}
}